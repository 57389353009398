a {
  text-decoration: none;
}
.header {
  background-color: #1DAF9E;
  height: 60px;
  display: flex;
  align-items: center;
}

.header .logo {
  height: 20px;
  padding-left: 20px;
}

.main {
  padding: 20px;
  background-color: #F5F5F5;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  box-sizing: border-box;
}

.main .description {
  margin-bottom: 20px;
}

.main .description .title {
  font-weight: bold;
}

.main .question_wrapper {
  margin-bottom: 20px;
}

.question_wrapper .question_title {
  font-size: 20px;
  font-weight: bold;
}

.question_wrapper .answers {
  padding: 20px;
  border-bottom: 2px solid #E0E0E0;
}

.diagnose_btn {
  background-color: #1DAF9E;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 80%;
  cursor: pointer;
}

.result_header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.result_main {
}

.result_main .result_title {
  background-color: #1DAF9E;
  color: #fff;
  padding: 20px;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.result_main .result_contents {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.result_main .result_score_wrapper {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 60%;
  margin: 0 auto;
}

.result_main .result_image {
  width: 100%;
}

.result_main .result_text {
  margin-bottom: 40px;
}

.advice_card {
  margin-bottom: 40px;
}

.advice_title {
  font-size: 30px;
  font-weight: bold !important;
  color: #fff;
  background-color: #1DAF9E;
  padding: 20px;
}

.advice_item {
  margin-bottom: .5rem;
}

.recommend_text {
  font-size: 40px;
  font-weight: bold;
  color: #1DAF9E;
  margin-bottom: 20px;
  text-align: center;
}

.recommend_text .logo {
  margin-right: 10px;
  max-width: 100%;
}

.contact_btn {
  background-color: #1DAF9E;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.result_footer {
  height: 100px;
  width: 100%;
  background-color: #1DAF9E;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.back_btn {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: #fff;
}
